import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import loadable from "@loadable/component"
const Carousel = loadable(() => import("@brainhubeu/react-carousel"))
import { Dots, slidesToShowPlugin } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

import globals from "./globals"
import { Container, ContentBox, H3 } from "./styledcomponents"
import Button from "./button"

import { cardList } from "./schedule-cardlist"

import Icons from "./icons"

const Icon = styled(Icons)`
  color: ${globals.color.lightShades};
  height: 1em;
  margin-right: 0.5em;
  overflow: visible;
`

const CardDiv = styled.div`
  text-align: left;
  min-width: 300px;
  max-width: 450px;
  min-height: 650px;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  justify-content: center;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: white;
  margin: 30px 15px 30px 15px;
  border-radius: 10px;
`

const TextBox = styled(ContentBox)`
  flex-grow: 1;
`

const TopicH = styled(H3)`
  &&:after {
  ${(props) =>
    props.noLine
      ? undefined
      : `
  display: block;
  content: '';
  border: solid ${globals.color.main};
  width: 3em;
  margin-top: 0.3em;
 `}
`

function Card(props) {
  const [value, setValue] = useState(7)
  const cards = cardList

  const onChange = (value) => {
    setValue(value)
  }

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const files = useStaticQuery(graphql`
    query files {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(JPG)|(JPEG)|(webp)|(jpeg)/" }
          dir: { regex: "/(Schedule)/" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Container margin="2em 0">
      <h2>Unser Kursangebot</h2>

      <Carousel
        value={value}
        onChange={onChange}
        className="topicsCarousel"
        plugins={[
          "arrows",
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 4,
            },
          },
        ]}
        breakpoints={{
          640: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1,
                  arrows: false,
                },
              },
            ],
          },
          1060: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 2,
                  arrows: false,
                },
              },
            ],
          },
          1380: {
            plugins: [
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 3,
                  arrows: true,
                },
              },
            ],
          },
        }}
      >
        {cards.map(({ id, name, img, text }) => (
          <CardDiv key={id}>
            {img && (
              <GatsbyImage
                image={
                  files.allFile.edges.filter(
                    (edge) => edge.node.base === img
                  )[0].node.childImageSharp.gatsbyImageData
                }
                style={{ borderRadius: "10px 10px 0 0" }}
                alt={name}
              />
            )}
            <TextBox margin="0" padding="0 1em">
              <TopicH>{name}</TopicH>
              <div className="cardText">{text}</div>
            </TextBox>
            <Button
              width="70%"
              margin="0 0 1.5em 0"
              to="https://app.team-laurien-alex.de/"
            >
              Stunde Buchen
            </Button>
          </CardDiv>
        ))}
      </Carousel>

      <Dots value={value} onChange={onChange} number={cards.length} />
    </Container>
  )
}

export default Card

import React from "react"
import Link from "gatsby-link"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from "../components/seo"
import Schedule from "../components/schedule"
import Card from "../components/schedule-card"
import { ContactForm } from "../components/contactform_new"
import ContentAndPicture from "../components/content-and-picture"

import { scrollToRef } from "../components/scrollTo"
import Button from "../components/button"
import { Grid, H2, P } from "../components/styledcomponents"

const Image = () => {
  const data = useStaticQuery(graphql`
    {
      placeholderImage: file(
        relativePath: { eq: "Schedule/Pfingstferien-stundenplan-2022.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <GatsbyImage
      image={data.placeholderImage.childImageSharp.gatsbyImageData}
      alt="Pfingstferien Stundenplan 2022"
    />
  )
}
// #<script type="text/javascript">
// (function (ma,rt,ia,ly,ti,c,s) {
// ma['bookings']=ly;ma[ly] = ma[ly] || function () { (ma[ly].q = ma[ly].q || []).push(arguments) };
// c = rt.createElement(ia), s = rt.getElementsByTagName(ia)[0];
// c.id = ly; c.src = ti; c.async = 1; s.parentNode.insertBefore(c, s);
// }(window, document, 'script', 'ma', '//services.martialytics.com/widgets/bookings/loader.js'));
// ma('init', {
//   'school': '7907', 
//   'hash': 'afcc9f6f80795ae8b7b1e4ee74d8fea8',
//   'styles': 'width:450px;height:500px;position:relative;margin:0 auto;z-index:100000;',
//   'element': 'bookings_widget'
// });
// </script>

function returnWidgetUrl() {
  const host = 'services.martialytics.com'
  const s = '7907' //school
  const h = 'afcc9f6f80795ae8b7b1e4ee74d8fea8' //hash
  const student = false // ?
  const internal = false // ?
  const theme = '' // or 'dark', test it
  let antiCache = new Date().getMilliseconds()
  return `https://${host}/widgets/bookings/?s=${s}&a=${h}&t=${student}&n=${internal}&widget=true&theme=${theme}&preventCache=${antiCache}`
}

class SchedulePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.state.value = 5
    this.topicsRef = React.createRef()
    this.bookinngRef = React.createRef()
    this.state.bookService = ""
    this.state.rand = 0
  }

  selectService = (service) => {
    scrollToRef(this.bookinngRef)
    this.setState({ bookService: service })
    this.setState({ rand: Math.random() })
  }

  customClickEvent = (linkKey) => {
    // scrollToRef(this.topicsRef)
    // this.setState({ value: linkKey })
    navigate('#buchen')
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Stundenplan - Team Laurien & Alex"
          description="Die Yoga- und Kampfsportstunden in unserem Studio im \
          Münchner Norden. Ausprobieren lohnt sich! Tägliches Angebot von \
          Montag bis Samstag."
        />
        <div className="page">
          {/* <Image /> */}
          <h1>Stundenplan</h1>
          <Grid id="buchen"
            columns='1fr 1fr'
            maxWidth="800px"
            alignItems='start'
            gridGap='2em'
          >
            <ContactForm referral="Stundenplan" headline="Jetzt weitere Informationen anfordern:" />
            <div style={{ marginTop: '3em' }}>
              <H2 >Oder direkt eine Stunde buchen</H2>
              <iframe
                src={returnWidgetUrl()}
                width="100%" height="100%"
                frameborder="0"
                style={{ height: '536px', marginTop: '0', width: '450px', z_index: '100000' }}>
              </iframe>
            </div>
          </Grid>

          <Schedule customClickEvent={this.customClickEvent} />

          <Button to="#buchen">
            Stunde buchen
          </Button>
          {/* <div ref={(ref) => (this.bookinngRef = ref)} id="buchen">
            <Grid>
              <H2>Unsere Buchungs-App:</H2>
              <P>
                <Link to="/blog/die-tla-app/">
                  Weitere Informationen zur App
                </Link>
              </P>
              <Button to="https://app.team-laurien-alex.de/signup">
                Stunde buchen
              </Button>
            </Grid>
          </div> */}
          <div><P></P></div>
          {/* <div ref={(ref) => (this.topicsRef = ref)}>
            <Card value={this.state.value} selectService={this.selectService} />
          </div> */}
        </div>
      </Layout >
    )
  }
}

export default SchedulePage

import React from "react"
import styled from "styled-components"

import globals from "../globals"
import { Grid, P } from "../styledcomponents"

import Icons from "../icons"

const Icon = styled(Icons)`
  color: ${globals.color.main};
  height: 1.3em;
  margin-right: 0.5em;
  overflow: visible;
`

const Training = styled.button`
  box-shadow: inset -2px -3px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${globals.color.lightShades};
  border: none;
  @media (min-width: ${globals.media.tablet}) {
    ${(props) =>
    props.matches &&
    `grid-column-start: ${Math.floor(props.day) * 2 - ((props.day - Math.floor(props.day)).toFixed(1) == 0.2 ? 0 : 1)};
    grid-column-end: ${Math.floor(props.day) * 2 + ((props.day - Math.floor(props.day)).toFixed(1) == 0.1 ? 0 : 1)};
        grid-row-start: ${props.ticksStart};
        grid-row-end: ${props.ticksEnd};
        height: ${1.1 * (props.ticksEnd - props.ticksStart)}rem;
        `}
  }
`
const Text = styled(P)`
  line-height: 1em;
  text-align: center;
`

const Day = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.2em;
  padding: 0.5em;
  color: ${globals.color.lightShades};
  @media (min-width: ${globals.media.tablet}) { ${(props) =>
    props.matches && `grid-column-start: ${props.day * 2 - 1};
      grid-column-end: ${props.day * 2 + 1};
      `};}
    `

const Empty = styled.div`
display: flex;
@media(min - width: ${globals.media.tablet}) {
    ${(props) =>
    props.matches &&
    `grid-column-start: ${props.day};
    grid-row-start: ${props.ticksStart};
    grid-row-end: ${props.ticksEnd};
    height: ${0.9 * (props.ticksEnd - props.ticksStart)}rem;`
  };
}
`

const Key = styled.div`
color: white;
text - align: right;
align - self: center;
margin: 0 auto;
`

const Location = styled.div`
width: min - content;
text - align: right;
`

const ScheduleItem = ({
  name,
  day,
  start,
  end,
  ticksStart,
  ticksEnd,
  linkKey,
  matches,
  online,
  studio,
  customClickEvent,
}) => {
  const clickEvent = (key) => {
    customClickEvent("#buchen")
  }

  if (name === "empty") {
    return (
      <Empty
        matches={matches}
        day={day}
        ticksStart={ticksStart}
        ticksEnd={ticksEnd}
      >
        <Key>
          <Text>
            <Icon icon="houseUser" />
            Im Studio
          </Text>
          <Text>
            <Icon icon="laptop" />
            Online via Zoom
          </Text>
          <Text>
            <Icon icon="tree" />
            Am Fasaneriesee
          </Text>
        </Key>
      </Empty>
    )
  } else if (start !== "") {
    return (
      <Training
        onClick={clickEvent}
        matches={matches}
        day={day}
        ticksStart={ticksStart}
        ticksEnd={ticksEnd}
      >
        <Grid
          columns="auto 2em"
          columnsTablet="auto 2em"
          columnsMobile="auto 2em"
          alignItems="center"
          justifyItems="flex-start"
        >
          <div>
            <Text margin="0.3rem">{name}</Text>
            <Text margin="0.3rem">
              {start}- {end}
            </Text>
          </div>
          {/* <Location>
            {studio ? <Icon icon="houseUser" /> : undefined}
            {online ? <Icon icon="laptop" /> : undefined}
            {!studio && !online ? <Icon icon="tree" /> : undefined}
          </Location> */}
        </Grid>
      </Training>
    )
  } else {
    return (
      <Day day={day} matches={matches} >
        <Text center>{name}</Text>
      </Day>
    )
  }
}

export default ScheduleItem

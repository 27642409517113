import React from "react"

const ninjaText = (
  <>
    <p>Die ‚Kleinen Ninjas‘ fokussieren sich auf:</p>
    <ul>
      <li>die Entwicklung der motorischen Fähigkeiten</li>
      <li>die Verbesserung von Selbstbehauptung und Selbstvertrauen</li>
      <li>die Grundlagen für BJJ, Kickboxen & MMA</li>
    </ul>
  </>
)

const bjjKidsText = (
  <>
    <p>BJJ Kids Training: </p>
    <ul>
      <li>
        Ist ein effektiver Kampfsport der ohne Schläge und Tritte auskommt
      </li>
      <li>Setzt Mobbing ein Ende</li>
      <li>Bietet die Möglichkeit an Wettkämpfen teilzunehmen</li>
    </ul>
  </>
)

const kickboxenKidsText = (
  <>
    <p>Beim MMA und Kickboxen lernen die Kids:</p>
    <ul>
      <li>auf Spielerische Weise Disziplin und Konzentration</li>
      <li>eine bessere Körperhaltung</li>
      <li>fairen Umgang mit anderen</li>
    </ul>
  </>
)

const bjjErwachseneText = (
  <>
    <p>
      Wenn Du BJJ bei uns in einer sicheren und freundschaftlichen Atmosphäre
      trainierst und Dich auspowerst:
    </p>
    <ul>
      <li>Verbessert Du Deine körperliche und mentale Fitness schnell</li>
      <li>Kannst Stress effektiv abbauen</li>
    </ul>
  </>
)

const kickboxenErwachseneText = (
  <>
    <p>
      MMA ist die Kunst, Striking und Grappling Stile in ein effektives System
      zu integrieren:
    </p>
    <ul>
      <li>Shoot-Boxen</li>
      <li>Grapple Boxen an der Wand und in der Mitte</li>
      <li>Ground and Pound</li>
    </ul>
  </>
)

const sanfterFlowText = (
  <>
    <p>
      Sanft und langsam öffnen unseren Körper und Geist zu durch sanfte
      Haltungen (Asana) und Atemübungen (Pranayama).
    </p>
    <ul>
      <li>Für alle Level</li>
      <li>Intensität: gering – mittel</li>
      <li>Ashtanga / Iyengar Mischung</li>
    </ul>
  </>
)

const dynamischerFlowText = (
  <>
    <p>
      Diese Yogastunde ist ein aktiver und dynamischer Mix zwischen Ashtanga
      Vinyasa und Iyengar Yoga.
    </p>
    <ul>
      <li>Für Fortgeschrittene</li>
      <li>Intensität: mittel – hoch</li>
      <li>Ashtanga / Iyengar Mischung</li>
    </ul>
  </>
)

const yinText = (
  <>
    <p>
      Mit diesem Yogastil stimulieren wir die Struktur unseres Körpers, also
      tief liegendes Bindegewebe, Faszien, Bänder und Sehnen.
    </p>
    <ul>
      <li>Für alle Level</li>
      <li>Intensität: gering</li>
      <li>3 bis 5 Minuten in einer Körperhaltung</li>
    </ul>
  </>
)

const ashtangaText = (
  <>
    <p>
      Sanft und langsam öffnen unseren Körper und Geist zu durch sanfte
      Haltungen (Asana) und Atemübungen (Pranayama).
    </p>
    <ul>
      <li>Für alle Level</li>
      <li>Intensität: gering – mittel</li>
      <li>Ashtanga / Iyengar Mischung</li>
    </ul>
  </>
)

const familienYogaText = (
  <>
    <p>
      Starte dein Wochenende entspannt und lächelnd mit unserer
      Familien-Yogastunde am Samstag.
    </p>
    <ul>
      <li>Für die ganze Familie</li>
      <li>Während Sommermonaten bei schönem Wetter am Fasaneriesee</li>
      <li>Acro-, Ashtanga- und Iyengaryoga</li>
    </ul>
  </>
)

export const cardList = [
  {
    name: "Kleine Ninjas - Kampfsport für 3 - 5 jährige Kinder",
    img: "Kleine-Ninjas-Kampfsport-Muenchen.jpg",
    text: ninjaText,
    id: 0,
    book: "littleNinjas",
  },
  {
    name: "BJJ für Kinder",
    text: bjjKidsText,
    img: "BJJ-Kids.jpg",
    id: 1,
    book: "bjjKids",
  },
  {
    name: "Kickboxen für Kinder",
    img: "Kickboxen-Kinder-muay-thai-meunchen.jpeg",
    text: kickboxenKidsText,
    id: 2,
    book: "mmaKids",
  },
  {
    name: "BJJ für Jugendliche und Erwachsene",
    img: "BJJ-Muenchen-Erwachsene.jpg",
    text: bjjErwachseneText,
    id: 3,
    book: "bjj",
  },
  {
    name: "MMA/ Kickboxen für Jugendliche und Erwachsene",
    img: "Kickboxen-MMA-Muay-Thai.jpg",
    text: kickboxenErwachseneText,
    id: 4,
    book: "mma",
  },
  {
    name: "Sanfter Yoga Flow in die Woche",
    img: "Sanfter-Yoga-Flow-in-die-Woche.jpg",
    text: sanfterFlowText,
    id: 5,
    book: "sanfterFlow",
    bookOnline: "onlineSanfterFlow",
  },
  {
    name: "Dynamischer Yoga Flow",
    img: "Dynamischer-Yoga-Flow.jpg",
    text: dynamischerFlowText,
    id: 6,
    book: "dynamischerFlow",
    bookOnline: "onlineDynamischerFlow",
  },
  {
    name: "Yin Yoga",
    img: "Yin-Yoga.jpg",
    text: yinText,
    id: 7,
    bookOnline: "onlineYinYoga",
  },
  {
    name: "Ashtanga",
    img: "Ashtanga-für-Anfänger.jpg",
    text: ashtangaText,
    id: 8,
    book: "ashtanga",
    bookOnline: "onlineAshtanga",
  },
  {
    name: "Familien Yoga",
    img: "Yoga-Flow-Fortgeschrittene.jpeg",
    text: familienYogaText,
    id: 9,
    book: "familienYoga",
    bookOnline: "onlineFamilienYoga",
  },
]

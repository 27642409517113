import React from "react"
import styled from "styled-components"

import ScheduleItem from "../components/scheduleitem/scheduleitem"

import globals from "../components/globals"
import { P } from "../components/styledcomponents"
import Button from "../components/button"

const Text = styled(P)`
  line-height: 1em;
`

const ScheduleDiv = styled.div`
  display: grid;
  grid-gap: 3px 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  background-color: ${globals.color.darkShades};
  width: 100%;
  height: 100%;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 10px 30px 10px;

  grid-template-columns: ${(props) =>
    props.weekView === "week" ? "repeat(12, 1fr)" : "repeat(1, 1fr)"};

  ${(props) =>
    props.weekView === "list" &&
    `max-width: min(90vw, ${globals.media.mobile});`}

  @media(max-width: ${globals.media.tablet}) {
    max-width: min(90vw, ${globals.media.mobile});
    grid-template-columns: 1fr;
  }
`

const ToggleButton = styled(Button)`
  @media (max-width: ${globals.media.tablet}) {
    display: none;
  }
`

const Break = styled(Text)`
  ${(props) => props.weekView !== "week" && "display: none"}
  @media (min-width: ${globals.media.tablet}) {
    background-color: ${globals.color.lightAccent};
    grid-column-start: 1;
    grid-column-end: 13;
    grid-row-start: 14;
  }
  @media (max-width: ${globals.media.tablet}) {
    display: none;
  }
`

class Training {
  //0 = Monday, 1 = Tuesday etc
  constructor(name, day, start, end, linkKey, studio, online) {
    this.name = name
    this.day = day
    this.start = start
    this.end = end
    this.linkKey = linkKey
    this.ticksStart = timeToTicks(start)
    this.ticksEnd = timeToTicks(end)
    this.studio = studio
    this.online = online
  }
}

function timeToTicks(time) {
  //define time slots
  // calculate in 15 min slots
  const timeSlot = 15
  const slotsPerHour = 60 / timeSlot
  time = time.split(":")
  const ticks = parseInt(time[0]) * slotsPerHour + parseInt(time[1]) / timeSlot
  return ticks
}

function trainings() {
  const trainingsTable = {
    trainings: [
      new Training("Montag", 1, "", "", ""),
      new Training("Kleine Ninjas", 1, "15:30", "16:15", 0, false, false),
      new Training("BJJ für Kinder", 1, "17:15", "18:15", 1, true, false),
      new Training("BJJ", 1, "18:30", "20:00", 3, true, false),
      new Training(
        "Boxsacktraining",
        1,
        "20:00",
        "21:00",
        6,
        true,
        true
      ),

      new Training("Dienstag", 2, "", "", ""),
      new Training("Kleine Ninjas", 2, "16:00", "16:45", 2, true, false),
      new Training("BJJ für Kinder", 2, "17:00", "18:00", 1, true, false),
      new Training("MMA", 2.1, "18:30", "20:00", 4, true, false),
      new Training("BJJ NoGi", 2.2, "18:30", "20:00", 4, true, false),

      new Training("Mittwoch", 3, "", "", ""),
      new Training("Kickboxen für Kinder", 3, "17:00", "18:00", 1, true, false),
      new Training("MMA", 3, "18:00", "19:30", 4, true, false),
      new Training("BJJ", 3, "19:30", "21:00", 3, true, false),

      new Training("Donnerstag", 4, "", "", ""),
      new Training(
        "Kleine Ninjas",
        4,
        "15:30",
        "16:15",
        2,
        true,
        false
      ),
      new Training(
        "BJJ für Kinder",
        4,
        "17:15",
        "18:15",
        1,
        true,
        false
      ),
      new Training("MMA", 4.1, "18:30", "20:00", 3, true, false),
      new Training(
        "Yoga",
        4.2,
        "19:00",
        "20:00",
        8,
        true,
        true
      ),
      new Training(
        "Selbstverteidigung für Frauen",
        4,
        "20:15",
        "21:15",
        8,
        true,
        true
      ),

      new Training("Freitag", 5, "", "", ""),
      new Training(
        "Yoga",
        5,
        "10:00",
        "11:00",
        4,
        true,
        false
      ),
      new Training("Kickboxen für Kinder", 5, "17:00", "18:00", 2, true, false),
      new Training("BJJ", 5, "18:30", "20:00", 4, true, false),

      new Training("Samstag", 6, "", "", ""),
      new Training("BJJ", 6.1, "11:30", "13:00", 3, true, false),
      new Training("BJJ Kids Wettkampf", 6.2, "11:30", "13:00", 3, true, false),
      // new Training("empty", 6, "17:00", "20:30", 0),
    ],
  }
  trainingsTable.minMax = minMaxTicks(trainingsTable)
  trainingsTable.trainings.forEach(function (training) {
    training.ticksStart = training.ticksStart - trainingsTable.minMax[0]
    training.ticksEnd = training.ticksEnd - trainingsTable.minMax[0]
  })
  return trainingsTable
}

function minMaxTicks({ trainings }) {
  let min = 24 * 4
  let max = 0
  trainings.forEach(function (training) {
    if (training.start !== "") {
      min = Math.min(min, training.ticksStart)
      max = Math.max(max, training.ticksEnd)
    }
  })

  min = min - 2
  max = max - 1
  return [min, max, max - min]
}

class Schedule extends React.Component {
  constructor(props) {
    super(props)
    this.state = trainings()
    this.state.value = 3
    this.state.weekView = "week"
    this.topicsRef = React.createRef()
    if (props.filter === "BJJ") {
      this.state.filter = this.bjjFilter
    } else if (props.filter === "MMA") {
      this.state.filter = this.mmaFilter
    } else if (props.filter === "Yoga") {
      this.state.filter = this.yogaFilter
    } else if (props.filter === "Kampfsport") {
      this.state.filter = this.maFilter
    } else if (props.filter === "Kids") {
      this.state.filter = this.kidsFilter
    } else {
      this.state.filter = this.allFilter
    }
    this.state.filteredList = this.state.trainings.filter(this.state.filter)
  }

  allFilter = (training) => {
    return training.name !== ""
  }

  mmaFilter = (training) => {
    return (
      training.linkKey === "" ||
      training.linkKey === 0 ||
      training.linkKey === 2 ||
      training.linkKey === 4 ||
      training.linkKey === 10
    )
  }

  yogaFilter = (training) => {
    return (
      training.linkKey === "" ||
      training.linkKey === 5 ||
      training.linkKey === 6 ||
      training.linkKey === 7 ||
      training.linkKey === 8 ||
      training.linkKey === 9 ||
      training.linkKey === 10
    )
  }

  bjjFilter = (training) => {
    return (
      training.linkKey === "" ||
      training.linkKey === 0 ||
      training.linkKey === 1 ||
      training.linkKey === 3 ||
      training.linkKey === 10
    )
  }

  maFilter = (training) => {
    return (
      training.linkKey === "" ||
      training.linkKey === 0 ||
      training.linkKey === 1 ||
      training.linkKey === 2 ||
      training.linkKey === 3 ||
      training.linkKey === 4 ||
      training.linkKey === 10
    )
  }

  kidsFilter = (training) => {
    return (
      training.linkKey === "" ||
      training.linkKey === 0 ||
      training.linkKey === 1 ||
      training.linkKey === 2 ||
      training.linkKey === 10
    )
  }

  toggleView = (e) => {
    this.setState({
      weekView: this.state.weekView === "week" ? "list" : "week",
    })
  }

  render() {
    return (
      <>
        <ToggleButton customClickEvent={this.toggleView}>
          {this.state.weekView ? "Listenansicht" : "Wochenansicht"}
        </ToggleButton>

        <ScheduleDiv weekView={this.state.weekView}>
          {this.state.filteredList.map(({ ...otherProps }, index) => (
            <ScheduleItem
              key={index}
              {...otherProps}
              matches={this.state.weekView === "week"}
              customClickEvent={(e) => {
                if (this.props.customClickEvent) {
                  this.props.customClickEvent(e)
                }
              }}
            />
          ))}

          <Break center margin="0" padding="0" weekView={this.state.weekView}>
            ...
          </Break>
        </ScheduleDiv>
      </>
    )
  }
}

export default Schedule
